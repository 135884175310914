import { currencyFormatter } from "../utils/currencyFormatter";
import { useDispatch } from "react-redux";
import {
  addToSingleCart,
  addtoCart,
  removeAllFromCart,
} from "../features/products/cartSlice";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";

// import { useGTM } from "react-gtm-module";
import TagManager from "react-gtm-module";

const Card = ({ product }) => {
  // const { dataLayer } = useGTM();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const addToCartHandler = (product) => {
    dispatch(addtoCart(product, colorAtr, sizeAtr));
  };

  const viewItem = (product) => {
    navigate(`/productdetails/${product?.id}`);
  };
  const [cartQuantity, setCartQuantity] = useState(1);
  const [colorAtr, setColorAtr] = useState(null);
  const [sizeAtr, setSizeAtr] = useState(null);

  const handleBuyNow = () => {
    dispatch(removeAllFromCart());
    dispatch(addToSingleCart({ ...product, sizeAtr, colorAtr, cartQuantity }));
    navigate("/checkout");
  };

  return (
    <div className=" w-full  shadow-md rounded-md overflow-hidden">
      <Link
        onClick={() => viewItem(product)}
        to={`/productdetails/${product.id}`}
        className=" img  h-[11rem] flex  justify-center items-center  overflow-hidden"
      >
        <img
          src={`${process.env.REACT_APP_URL}/uploads/product/${product?.photos}`}
          alt="mehedi product"
          className="w-full h-full object-cover"
        />
      </Link>
      <div className="p-2 flex flex-col">
        <div onClick={() => viewItem(product)}>
          <div className="flex items-center justify-center h-14">
            {product.unit_price === product.after_discount &&
            product.after_discount > 0 ? (
              <div className="flex items-center justify-center">
                ৳ {product?.unit_price}
              </div>
            ) : (
              <div className="flex flex-col md:flex md:flex-row justify-between items-center text-center gap-2">
                <span className="font-semibold line-through text-[#FA5303] decoration-200">
                  ৳ {product?.unit_price}
                </span>
                <span className="">৳ {+product?.after_discount}</span>
              </div>
            )}
          </div>
          <h3 className="text-[1rem] h-[3rem]  font-normal text-[#212121] mb-2 overflow-hidden">
            {product?.name?.substring(0, 40)}
            ...
          </h3>
        </div>

        {product?.current_stock > 0 ? (
          <div className="flex flex-col text-center  gap-2 justify-center items-center text-gray-500">
            <button
              onClick={() => addToCartHandler(product)}
              className="bg-[#EB1E63]  text-slate-50 hover:bg-[#DA303D] text-white duration-300 w-full py-1 rounded-full"
            >
              Add to Cart
            </button>

            <button
              onClick={handleBuyNow}
              className="border text-center  bg-[#F9921F] hover:text-slate-50 ] text-white duration-300 w-full  rounded-full py-1"
            >
              Order Now
            </button>

            {/* to={"/productdetails/"} */}
          </div>
        ) : (
          <p>Out of Stock</p>
        )}
      </div>
    </div>
  );
};

export default Card;
